import Link from 'next/link';
import { useTranslation } from 'next-i18next';

export default function HeroSection() {
  const { t } = useTranslation('pages/home');
  const urlVideo = t('unlock_video');

  return (
    <section className='flex items-center justify-center lg:h-[95vh]'>
      <div className='relative mx-auto flex w-full max-w-[1400px] px-5 lg:h-screen xl:mt-[-128px]'>
        <div className='grid w-full items-center xl:grid-cols-2'>
          <div className=''>
            <div className='flex flex-col space-y-4'>
              <h1 className='text-xl font-bold leading-[1.1] text-white lg:text-[42px]'>{t('unlock')}</h1>
              <p className='pb-6 text-base font-[500] leading-[1.4] text-[#929298]'>{t('unlock_description')}</p>
            </div>
            <div className='flex flex-col gap-5 text-sm font-bold leading-[2.2] mobile-l:flex-row'>
              <Link href='/trial' className='w-full cursor-pointer rounded-xl bg-green-300 p-2.5 text-center text-sm font-bold uppercase text-black transition-opacity duration-200 hover:bg-opacity-90 hover:opacity-80 hover:shadow-xl mobile:px-10 tablet:w-fit'>
                {t('unlock_free_trial_button')}
              </Link>
              <Link href='/download' className='w-full cursor-pointer rounded-xl bg-blue-300 p-2.5 text-center text-sm font-bold uppercase text-[#3471A3] transition-opacity duration-200 hover:bg-opacity-90 hover:opacity-80 hover:shadow-xl mobile:px-10 tablet:w-fit'>
                {t('unlock_get_no_ping_button')}
              </Link>
            </div>
          </div>
          <video autoPlay loop muted className='relative -top-10 hidden max-w-[120%] xl:flex'>
            <source src={`/assets/video/${urlVideo}.webm`} type='video/webm' />
            <source src={`/assets/video/${urlVideo}.mp4`} type='video/mp4' />
            <source src={`/assets/video/${urlVideo}.ogv`} type='video/ogg' />
            Seu navegador não suporta a tag de vídeo.
          </video>
        </div>
      </div>
    </section>
  );
}
