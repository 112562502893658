import dynamic from 'next/dynamic';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect } from 'react';

import Header from '@/common/header';
import HeroSection from '@/components/home/hero-section';
import { customEvent } from '@/configs/sdk';
import NextSeoComponent from '@/layouts/next-seo-component';
import { Main } from '@/templates/main';

const SingleClickActivate = dynamic(() => import('@/components/home/single-click-activate'));
const Boost = dynamic(() => import('@/components/home/boost'));
const WhoUses = dynamic(() => import('@/components/home/who-uses'));
const Sponsors = dynamic(() => import('@/components/sponsors'));
const HomeCTA = dynamic(() => import('@/components/home/home-cta'));
const KeyFeatures = dynamic(() => import('@/components/home/key-features'));
const WorldMap = dynamic(() => import('@/components/home/world-map'));
const FAQ = dynamic(() => import('@/common/faq'));
const Footer = dynamic(() => import('@/common/footer'));

export default function Home() {
  useEffect(() => {
    customEvent('page_view');
  }, []);

  return (
    <Main meta={<NextSeoComponent title='NoPing - Dê um fim ao ping alto e FPS baixo.' description='Experimente uma jogabilidade de baixa latência com o uso de IA e desbloqueie seu máximo potencial em qualquer dispositivo. Melhorar o ping, aumentar o FPS e tirar o lag nunca foi tão simples. Teste o NoPing de graça agora!' />}>
      <Header variant='primary' />
      <HeroSection />
      <SingleClickActivate />
      <Boost />
      <WhoUses />
      <Sponsors />
      <HomeCTA />
      <KeyFeatures />
      <WorldMap />
      <FAQ shadowButton={false} />
      <Footer />
    </Main>
  );
}

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['pages/home', 'common', 'components/servers-world-map', 'components/faq', 'components/country-name', 'components/player-box'])),
    },
  };
}
