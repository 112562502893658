export function getCookie() {
  // @ts-ignore
  if (typeof window !== 'undefined' && window.Affiliate) {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    return Affiliate?.getCookie()?.clickId;
  }

  return '';
}

/**
 * Save custom event
 * @param {string} action - The name of the custom event to be saved
 */
export async function customEvent(action) {
  const cookie = getCookie();

  try {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    await Affiliate.customAction(cookie, action);
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}

/**
 * Identify the frequency of the plan and save it as a custom action
 * @param {any} frequency - The frequency in days to determine the type of plan (e.g., 30 for monthly, 365 for yearly)
 */
export async function identifySalesPlan(frequency) {
  let frequencyPlan = '';
  switch (frequency) {
    case 30:
      frequencyPlan = 'venda_mensal';
      break;
    case 365:
      frequencyPlan = 'venda_anual';
      break;
    default:
      frequencyPlan = 'venda_semanal';
      break;
  }

  await customEvent(frequencyPlan);
}
